import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
import APIDoc from "../../src/components/apidoc";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "linked-events"
    }}>{`Linked Events`}</h1>
    <p>{`Provides data about events around the City of Helsinki`}</p>
    <p>{`Linked Events provides categorized data on events and places for the City of Helsinki. It serves as the common event database for all Helsinki city divisions and the `}<a parentName="p" {...{
        "href": "http://www.hel.fi/www/helsinki/fi/tapahtumakalenteri"
      }}>{`hel.fi web services`}</a>{`.`}</p>
    <p>{`In the API, you can search data by date or location as well as bounding box. The API provides data in JSON-LD format.`}</p>
    <ul>
      <li parentName="ul">{`API location: `}<a parentName="li" {...{
          "href": "https://api.hel.fi/linkedevents/v1/"
        }}>{`api.hel.fi/linkedevents/v1/`}</a></li>
    </ul>
    <h2 {...{
      "id": "data-license"
    }}>{`Data license`}</h2>
    <p>{`Event data is licensed using CC BY 4.0 license. `}<a parentName="p" {...{
        "href": "http://creativecommons.org/licenses/by/4.0/deed.fi"
      }}>{`FI`}</a>{` | `}<a parentName="p" {...{
        "href": "http://creativecommons.org/licenses/by/4.0/deed.sv"
      }}>{`SV`}</a>{` | `}<a parentName="p" {...{
        "href": "http://creativecommons.org/licenses/by/4.0/deed.en"
      }}>{`EN`}</a>{` However, some images within the events may have a license "event_only" listed in the API, in which case the image is subject to a slightly more `}<a parentName="p" {...{
        "href": "https://api.hel.fi/linkedevents/v1"
      }}>{`strict license`}</a>{`.`}</p>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <p><a parentName="p" {...{
        "href": "https://api.hel.fi/linkedevents/v1/"
      }}>{`The browsable API`}</a>{` contains usage examples if you choose to delve right in. The full API documentation is provided below.`}</p>

    <APIDoc doc="https://raw.githubusercontent.com/City-of-Helsinki/api-linked-events/master/linked-events.swagger.yaml" mdxType="APIDoc" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      